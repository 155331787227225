import React, { createContext, useState } from 'react';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [type, setType] = useState(''); // 'error' or 'success'

 
   const displayError = (msgs) => {

     if (typeof msgs === 'object' && !Array.isArray(msgs)) {
       // Flatten the error messages object into an array of strings
       const flatMessages = [];
       for (const key in msgs) {
         if (Array.isArray(msgs[key])) {
            console.log('message')
           msgs[key].forEach((msg) => flatMessages.push(`${key}: ${msg}`));
           console.log(flatMessages)
         }
         
       }

       console.log('outside')
       console.log(flatMessages)
       
       setMessages(flatMessages);
     } else if (Array.isArray(msgs)) {
       setMessages(msgs);
     } else {
       setMessages([msgs]);
     }
     setType('error');
     setTimeout(() => {
       setMessages([]);
       setType('');
     }, 3000);
   };
 
   const displaySuccess = (msg) => {
     setMessages([msg]);
     setType('success');
     setTimeout(() => {
       setMessages([]);
       setType('');
     }, 3000);
   };


// const displayError = (msgs) => {
//    if (Array.isArray(msgs)) {
//      setMessages(msgs);
//    } else {
//      setMessages([msgs]);
//    }
//    setType('error');
//    setTimeout(() => {
//      setMessages([]);
//      setType('');
//    }, 3000);
//  };


//   const displaySuccess = (msgs) => {
//     setMessages([msgs]);
//     setType('success');
//     setTimeout(() => {
//       setMessages([]);
//       setType('');
//     }, 3000);
//   };

  return (
    <MessageContext.Provider value={{ messages, type, displayError, displaySuccess }}>
      {children}
    </MessageContext.Provider>
  );
};
