import React, {useEffect, useState , useContext }  from 'react';
import SingleVideoPlayer from '../SingleVideoPlayer/SingleVideoPlayer';
import { MessageContext } from '../../MessageContext';
import { useParams } from 'react-router-dom';
import './Course.css';
import api from '../../../api';

const  Course = () => {

   const [videos, setVideos] = useState({});
   const [activeTab, setActiveTab] = useState("Transcript");

   const { id } = useParams();

   const { displayError ,displaySuccess} = useContext(MessageContext);
   // const {  } = useContext(MessageContext);

   const handleTabChange = (tab) => {
      setActiveTab(tab);
    };

    const truncateText = (text, maxLength) => {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...pdf";
    };

   const downloadFile = async (resourceId,fileName) => {
      try {
   
      const response = await api.get(`courses/download/resource/${resourceId}`, {
         responseType: 'blob',
      });


      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      
      link.href = url;

      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      link.remove();

      window.URL.revokeObjectURL(url);

      displaySuccess('File downloaded successfully');

      } catch (error) {

      console.error('Error downloading file:', error);

      displayError('Error downloading file:');
      }
   };
   
  

   useEffect(() => {
      api.get(`/courses/${id}`)  
        .then(response => {
         console.log(response)
         if(response.data.success){
            setVideos(response.data.data);
            displaySuccess(response.data.message)
         }else{
            displaySuccess(response.data.errors)
            setVideos({});
         }
          
        })
        .catch(error => {
         // displayError(error.response.data.errors);
         //  console.error('Error fetching videos:', error);
         displayError(error.response?.data?.errors || 'Error fetching videos');
         console.error('Error fetching videos:', error);
        });
      //   id, displayError, displaySuccess
    }, []);
  return (
    <div>
         <div className="single_video_component">
   
            {videos.video_url ? (
               <SingleVideoPlayer
                  videoUrl={videos.video_url}
                  topic={videos.title}
                  duration={videos.duration}
                  points={videos.points}
                  category={videos.category}
                  id={videos.id}
               />
            ) : (
               <p>No video available</p>
            )}
            
        </div>
        <div className='single_video_resource'>
        <div className="tabs-container">
      
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "Transcript" ? "active" : ""}`}
          onClick={() => handleTabChange("Transcript")}
        >
          Transcript
        </button>
        <button
          className={`tab-button ${activeTab === "Resource" ? "active" : ""}`}
          onClick={() => handleTabChange("Resource")}
        >
          Resource
        </button>
        <button
          className={`tab-button ${activeTab === "About" ? "active" : ""}`}
          onClick={() => handleTabChange("About")}
        >
          About
        </button>
      </div>

      {/* Tabs Content */}
      <div className="tab-content">
        {activeTab === "Transcript" && (
          <div>
            <h2>Home Content</h2>
            <p>Welcome to the Home page!</p>
          </div>
        )}
        {activeTab === "Resource" && (
         
          <div>
          {videos.resources.length > 0 ? (
            <div style={{ display : 'flex' , flexWrap : 'wrap'}}>
            {videos.resources.map((resource, index) => (
            <div class="fileBox" >
            <div style={{ display: 'flex' , justifyContent :'space-between'}}>
             <div >
               <div style={{ display :'flex' ,alignItems: 'center'}} className='fileName'>
                  <i class='bx bx-file-blank' ></i>
                  <p>{truncateText(resource.fileName ,15)}</p>
               </div>
               <p className='mb_size'> {resource.size} MB</p>
             </div>
               <p  className='download_icon'>
               <a  onClick={() => downloadFile(resource.id , resource.fileName)}>
                  <i class='bx bx-download'></i>
               </a>
               </p>
            </div>
            </div>
         ))}
         </div>
         ): (
            <div className="empty_resource">
               <h3>No Resource </h3>
            </div>
            )
           }
          </div>
        
        )}
        {activeTab === "About" && (
          <div>
            <h2 style={{ marginBottom :'1rem' }}>Title</h2>
            <p style={{ marginBottom :'1rem' }}>{videos.title}</p>
            <h2 style={{ marginBottom :'1rem' }}>Description</h2>
            <p>{videos.description}</p>
          </div>
        )}
      </div>
    </div>
        
        </div>
    </div>
  )
}


export default Course;
