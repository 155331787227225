import React, { useState, useEffect, useContext } from "react";
import { MessageContext } from '../../MessageContext';
import api from '../../../api';
import './Leaderboard.css';
import award from '../../../assets/award.png';
import LearnerImg from '../../../assets/learner_img.png';

export default function Leaderboard() {

   const [results, setResults] = useState([]); 
   const [topStudent, setTopStudent] = useState({}); 
   const [durationType, setDurationType] = useState('all_time'); 

   const { displayError, displaySuccess } = useContext(MessageContext);

   const fetchLeaderboardData = (type) => {
      api.get(`/courses/leaderboard/scores?type=${type}`)
        .then(response => {
          console.log(response);
          if (response.data.success) {
            setResults(response.data.data.leaderboards.data);
            setTopStudent(response.data.data.topStudent);
            displaySuccess(response.data.message);
          } else {
            displayError(response.data.errors);
          }
        })
        .catch(error => {
          displayError(error.response.data.errors);
        });
    };

   useEffect(() => {
      fetchLeaderboardData(durationType);
  }, [durationType]);
  return (
    <div className="leaderboard leaderboardBox">
      <div className="leaderboard_header leaderboard_top_header">
           <div className="top_student_box">
               <div className="top_student_header">
                  <img src={award} alt='award'/>
               </div>
               <div className="top_student_footer">
                <div>
                {topStudent ? (
                  <div className="student_data">
                     <img src={LearnerImg } alt='learner-img'/>
                     <h2>{topStudent.name}</h2>
                  </div>
                  ) : (
                  <p>Loading...</p>
                  )}
                </div>
               </div>
           </div>

           <div className="toggle_dureation">
             <div className="buttons">
               <button
                  onClick={() => setDurationType('all_time')}
                  className={durationType === 'all_time' ? 'active' : ''}
               
               >All</button>
               <button 
                 onClick={() => setDurationType('this_week')}
                 className={durationType === 'this_week' ? 'active' : ''}
               >This week</button>
             </div>
           </div>
      </div>
      <div className="leaderboard_body">

      {results.map((result, index) => (
         <div className="leaderboard_result">
               <div className="details" key={result.id || index}>
                  <h4 className="name"><span>{index+1}</span>{result.name}</h4>
                  <h4>{result.completed_courses}</h4>
                  <h4 className="points">{result.total_score}pts</h4>
               </div>
         </div>
       ))}


      </div>
    </div>
  )
}
