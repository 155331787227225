// import React, { useState, useEffect, useContext } from "react";
// import './Quiz.css';
// import { MessageContext } from '../../MessageContext';
// import api from '../../../api';
// import { useParams , useNavigate} from 'react-router-dom';

// export default function Quiz() {
//     const [questions, setQuestions] = useState([]);
//     const [course, setCourse] = useState([]);
//     const [answers, setAnswers] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState('');
//     const [isSubmitted, setIsSubmitted] = useState(false); 
//     const [score, setScore] = useState(0); 
//     const { id } = useParams();
//     const { displayError, displaySuccess } = useContext(MessageContext);
//     const [points , setPoints] = useState(0);
//     const [nextCourseId, setNextCourseId] = useState(null); 
//     const navigate = useNavigate(); 
 

//     useEffect(() => {

//       fetchQuizData()

//     }, [id]);

//     const truncateText = (text, maxLength) => {
//       if (!text) return ''; 
//       if (text.length <= maxLength) return text; 
//       return text.slice(0, maxLength) + '...'; 
//     };
    

    
//     const fetchQuizData = () => {
//       setLoading(true); 
//       api.get(`/courses/quiz/${id}`)
//       .then(response => {
//         console.log(response) 

//           if (response.data.success) {
            
//               displaySuccess(response.data.message);
//               setQuestions(response.data.data.questions);
//               setCourse(response.data.data.course);

//               const totalPoints = response.data.data.questions.reduce((acc, question) => {
//                const questionPoints = parseInt(question.points, 10) || 0;
//                return acc + questionPoints;
//               }, 0);
//               setPoints(totalPoints); 

//                const userScores = response.data.data.leaderboard; 
//                if(userScores.length > 0) {         
//                   const totalScore = userScores.reduce((total, current) => {
//                      return total + parseInt(current.score, 10);
//                   }, 0);
//                   setScore(totalScore);
//                   setIsSubmitted(true)
//                }

//                setNextCourseId(response.data.data.nextCourse.id); 
//               setLoading(false);
//           } else {
//               setQuestions([]);
//               setLoading(false);
//               setError('No questions available for this quiz.');
//           }
//           setLoading(false);
//       })
//       .catch(error => {
//            setError('Error fetching questions');
//          //  displayError(error.response.data.errors);
//           console.error('Error fetching questions:', error);
//           setLoading(false);
//       });
//     }

//     const handleRadioChange = (questionId, choiceId) => {
//         setAnswers(prevAnswers => ({
//             ...prevAnswers,
//             [questionId]: choiceId,
//         }));
//     };


//     const goToNextPage = () => {
//       console.log(nextCourseId)
//       navigate(`/course/${nextCourseId}`);
//      };

//      const handleRetakeQuiz = () => {
//       setIsSubmitted(false);
//       setScore(0);
//       setAnswers({});
//       // fetchQuizData(); 
//   };

//     // Handle quiz submission
//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const payload = {
//             answers: Object.keys(answers).map(questionId => ({
//                 question_id: questionId,
//                 answer_id: answers[questionId],
//             })),
//         };

//         api.post('/courses/submit/quiz', payload)
//             .then(response => {
//                 if (response.data.success) {
//                     setIsSubmitted(true); 

//                     displaySuccess(response.data.message);

            
//                     setTimeout(() => {
//                      window.location.reload();
//                    }, 3000);
//                 } else {
//                   displayError(response.data.errors)
//                 }
//             })
//             .catch(error => {
//                displayError('Error submitting quiz:', error)
              
//             });
//     };

//     const progressPercentage = (score / points) * 100;

//     const progressBarClass = progressPercentage < 50 ? 'progress-bar red' : 'progress-bar green';
//     const resultBoxClass = progressPercentage < 50 ? 'result_box red' : 'result_box green';
//     const scoreBox = progressPercentage < 50 ? 'score red' : 'score green';
//     const resultMsg = progressPercentage < 50 ? 'result_message red' : 'result_message green';
//     const resultMessage = progressPercentage < 50 ? 'You didnt pass this time , study up with our recommended resources and try again' : 'Congratulation !!! Excellent Job';
//     const btnControls = progressPercentage < 50 ? 'btn_control red' : 'btn_control green';
//     const btnText = progressPercentage < 50 ? 'Re Take Quiz': 'Next Course';

//     return (
//         <div className='quiz_body'>
//             <div className="quiz_header">
//                 <span><i className='bx bx-left-arrow-alt'></i> BACK</span>
//                 <div className='quiz_title'>
//                     <h1>{course.title}</h1>
//                     <span>{truncateText(course?.description, 350)}</span>
//                 </div>
//                 <div className='quiz_mins'>
//                     {course.category} . <span>+{course.points} points</span> > 2 hours 30 mins
//                 </div>
//             </div>

//             {isSubmitted && (
//                <div className={resultBoxClass }>
//                   <div className="result_container">
//                            <h1>{course.title}</h1>
//                            <p className={resultMsg}>{resultMessage}</p>
//                            <div className='progress-bar-container'>
//                               <div className={progressBarClass} style={{ width: `${progressPercentage}%` }}>
//                                     {progressPercentage.toFixed(0)}%
//                               </div>
//                           </div>
//                     <div className='score'>
//                       <h3>Score : <span className={scoreBox}>{score }/{points}</span></h3>
//                     </div>
//                      <div style={{ display:'flex', justifyContent:'center' , marginTop: '2rem'}}>
//                         {/* <button className={btnControls} onClick={goToNextPage}>{btnText}</button> */}
//                         <button className={btnControls} onClick={progressPercentage < 50 ? handleRetakeQuiz : goToNextPage}>
//                                 {btnText}
//                         </button>
//                      </div>
//                   </div>
//             </div>
//             )}
//            {questions.length > 0 ? (
//                <div className='quiz'>
//                   <form className='quiz' onSubmit={handleSubmit}>
//                      {questions.map((question, index) => (
//                            <div className="questions" key={question.id}>
//                               <h2>Question {index + 1}</h2>
//                               <h4>{question.question_text}</h4>
//                               <span>Select one answer</span>
//                               <div className='answers'>
//                                  <ul>
//                                        {question.choices.map((choice) => {
//                                           const isCorrect = question.user_answers?.choice_id === choice.id && question.user_answers?.is_correct;
//                                           const isIncorrect = question.user_answers?.choice_id === choice.id && !question.user_answers?.is_correct;

//                                           return (
//                                              <li key={choice.id} className={isSubmitted ? (isCorrect ? 'correct' : (isIncorrect ? 'incorrect' : '')) : ''}>
//                                                    <label>
//                                                       <input
//                                                          type="radio"
//                                                          name={`question-${question.id}`}
//                                                          value={choice.id}
//                                                          checked={
//                                                                isSubmitted
//                                                                   ? question.user_answers?.choice_id === choice.id
//                                                                   : answers[question.id] === choice.id
//                                                          }
//                                                          onChange={() => handleRadioChange(question.id, choice.id)}
//                                                          disabled={isSubmitted} // Disable radio buttons after submission
//                                                       />
//                                                       {choice.choice_text}
//                                                    </label>
//                                              </li>
//                                           );
//                                        })}
//                                  </ul>
//                               </div>
//                            </div>
//                      ))}
//                      {!isSubmitted && <button type="submit">Submit Quiz</button>}
//                   </form>
//                </div>
//             ): (
//                <div className="empty_questions">
//                  <h3>No question set for this course</h3>
//                  <button onClick={goToNextPage}>Next Course</button>
//                </div>
//             )
//            }
//         </div>
//     );
// }


// import React, { useState, useEffect, useContext } from "react";
// import './Quiz.css';
// import { MessageContext } from '../../MessageContext';
// import api from '../../../api';
// import { useParams, useNavigate } from 'react-router-dom';

// export default function Quiz() {
//     const [questions, setQuestions] = useState([]);
//     const [course, setCourse] = useState([]);
//     const [answers, setAnswers] = useState({});
//     const [loading, setLoading] = useState(true); // Loading state
//     const [error, setError] = useState('');
//     const [isSubmitted, setIsSubmitted] = useState(false);
//     const [score, setScore] = useState(0);
//     const { id } = useParams();
//     const { displayError, displaySuccess } = useContext(MessageContext);
//     const [points, setPoints] = useState(0);
//     const [nextCourseId, setNextCourseId] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchQuizData();
//     }, [id]);

//     const truncateText = (text, maxLength) => {
//         if (!text) return '';
//         if (text.length <= maxLength) return text;
//         return text.slice(0, maxLength) + '...';
//     };

//     const fetchQuizData = () => {
//         setLoading(true); // Set loading to true when API call starts
      
//         api.get(`/courses/quiz/${id}`)
//             .then(response => {
//                 if (response.data.success) {
//                     displaySuccess(response.data.message);
//                     setQuestions(response.data.data.questions);
//                     setCourse(response.data.data.course);

//                     const totalPoints = response.data.data.questions.reduce((acc, question) => {
//                         const questionPoints = parseInt(question.points, 10) || 0;
//                         return acc + questionPoints;
//                     }, 0);
//                     setPoints(totalPoints);

//                     const userScores = response.data.data.leaderboard;
//                     if (userScores.length > 0) {
//                         const totalScore = userScores.reduce((total, current) => {
//                             return total + parseInt(current.score, 10);
//                         }, 0);
//                         setScore(totalScore);
//                         setIsSubmitted(true);
//                     }

//                     setNextCourseId(response.data.data.nextCourse.id);
//                 } else {
//                     setQuestions([]);
//                     setError('No questions available for this quiz.');
//                 }
//                 setLoading(false); 
            
//             })
//             .catch(error => {
//                //  setError('Error fetching questions');
//                //  displayError('Error fetching questions:', error);
//                 setLoading(false); 
//             });
//     };

//     const handleRadioChange = (questionId, choiceId) => {
//         setAnswers(prevAnswers => ({
//             ...prevAnswers,
//             [questionId]: choiceId,
//         }));
//     };

//     const goToNextPage = () => {
//         navigate(`/course/${nextCourseId}`);
//     };

//     const handleRetakeQuiz = () => {
//         setIsSubmitted(false);
//         setScore(0);
//         setAnswers({});
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const payload = {
//             answers: Object.keys(answers).map(questionId => ({
//                 question_id: questionId,
//                 answer_id: answers[questionId],
//             })),
//         };
//         setLoading(true); 
//         api.post('/courses/submit/quiz', payload)
       
//             .then(response => {
//                 if (response.data.success) {
//                     setIsSubmitted(true);
//                     displaySuccess(response.data.message);

//                     setTimeout(() => {
//                         window.location.reload();
//                     }, 3000);
//                 } else {
//                     displayError(response.data.errors);
//                 }
//                 setLoading(false); 
//             })
//             .catch(error => {
//                 displayError('Error submitting quiz:', error);
//                 setLoading(false); 
//             });
//     };

//     const progressPercentage = (score / points) * 100;

//     const progressBarClass = progressPercentage < 50 ? 'progress-bar red' : 'progress-bar green';
//     const resultBoxClass = progressPercentage < 50 ? 'result_box red' : 'result_box green';
//     const scoreBox = progressPercentage < 50 ? 'score red' : 'score green';
//     const resultMsg = progressPercentage < 50 ? 'result_message red' : 'result_message green';
//     const resultMessage = progressPercentage < 50
//         ? 'You didn\'t pass this time, study up with our recommended resources and try again'
//         : 'Congratulations! Excellent Job';
//     const btnControls = progressPercentage < 50 ? 'btn_control red' : 'btn_control green';
//     const btnText = progressPercentage < 50 ? 'Re Take Quiz' : 'Next Course';

//     return (
//         <div className='quiz_body'>
//             <div className="quiz_header">
//                 <span><i className='bx bx-left-arrow-alt'></i> BACK</span>
//                 <div className='quiz_title'>
//                     <h1>{course.title}</h1>
//                     <span>{truncateText(course?.description, 350)}</span>
//                 </div>
//                 <div className='quiz_mins'>
//                     {course.category} . <span>+{course.points} points</span> > 2 hours 30 mins
//                 </div>
//             </div>

//             {/* Loading spinner or message */}
//             {loading ? (
//                 <div className="loading">Loading...</div>
//             ) : isSubmitted && loading ? (
//                 <div className={resultBoxClass}>
//                     <div className="result_container">
//                         <h1>{course.title}</h1>
//                         <p className={resultMsg}>{resultMessage}</p>
//                         <div className='progress-bar-container'>
//                             <div className={progressBarClass} style={{ width: `${progressPercentage}%` }}>
//                                 {progressPercentage.toFixed(0)}%
//                             </div>
//                         </div>
//                         <div className='score'>
//                             <h3>Score: <span className={scoreBox}>{score}/{points}</span></h3>
//                         </div>
//                         <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
//                         {nextCourseId || progressPercentage < 50 ? (
//                               <button className={btnControls} onClick={progressPercentage < 50 ? handleRetakeQuiz : goToNextPage}>
//                                  {btnText}
//                               </button>
//                            ) : (
//                               <button className={btnControls} onClick={() => navigate('/')}>
//                                  Module Completed Go Home
//                               </button>
//                            )}
//                         </div>
//                     </div>
//                 </div> 
//             )}
            
//             { questions.length > 0 ? (
//                 <div className='quiz'>
//                     <form className='quiz' onSubmit={handleSubmit}>
//                         {questions.map((question, index) => (
//                             <div className="questions" key={question.id}>
//                                 <h2>Question {index + 1}</h2>
//                                 <h4>{question.question_text}</h4>
//                                 <span>Select one answer</span>
//                                 <div className='answers'>
//                                     <ul>
//                                         {question.choices.map((choice) => {
//                                             const isCorrect = question.user_answers?.choice_id === choice.id && question.user_answers?.is_correct;
//                                             const isIncorrect = question.user_answers?.choice_id === choice.id && !question.user_answers?.is_correct;

//                                             return (
//                                                 <li key={choice.id} className={isSubmitted ? (isCorrect ? 'correct' : (isIncorrect ? 'incorrect' : '')) : ''}>
//                                                     <label>
//                                                         <input
//                                                             type="radio"
//                                                             name={`question-${question.id}`}
//                                                             value={choice.id}
//                                                             checked={
//                                                                 isSubmitted
//                                                                     ? question.user_answers?.choice_id === choice.id
//                                                                     : answers[question.id] === choice.id
//                                                             }
//                                                             onChange={() => handleRadioChange(question.id, choice.id)}
//                                                             disabled={isSubmitted}
//                                                         />
//                                                         {choice.choice_text}
//                                                     </label>
//                                                 </li>
//                                             );
//                                         })}
//                                     </ul>
//                                 </div>
//                             </div>
//                         ))}
//                         {!isSubmitted && <button type="submit">Submit Quiz</button>}
//                     </form>
//                 </div>
//             ) : (
//                 <div className="empty_questions">
//                     <h3>No questions set for this course</h3>
//                     <button onClick={goToNextPage}>Next Course</button>
//                 </div>
//             )}
//         </div>
//     );
// }


import React, { useState, useEffect, useContext } from "react";
import './Quiz.css';
import { MessageContext } from '../../MessageContext';
import api from '../../../api';
import { useParams, useNavigate } from 'react-router-dom';

export default function Quiz() {
    const [questions, setQuestions] = useState([]);
    const [course, setCourse] = useState({});
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [quizSubmitted, setQuizSubmitted] = useState(false);
    const [score, setScore] = useState(0);
    const { id } = useParams();
    const { displayError, displaySuccess } = useContext(MessageContext);
    const [points, setPoints] = useState(0);
    const [nextCourseId, setNextCourseId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchQuizData();
    }, [id]);

    const truncateText = (text, maxLength) => {
        if (!text) return '';
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const fetchQuizData = () => {
        setLoading(true); 

        api.get(`/courses/quiz/${id}`)
            .then(response => {
                if (response.data.success) {
                    displaySuccess(response.data.message);
                    setQuestions(response.data.data.questions);
                    setCourse(response.data.data.course);

                    const totalPoints = response.data.data.questions.reduce((acc, question) => {
                        return acc + (parseInt(question.points, 10) || 0);
                    }, 0);
                    setPoints(totalPoints);

                    const userScores = response.data.data.leaderboard;
                    if (userScores.length > 0) {
                        const totalScore = userScores.reduce((total, current) => {
                            return total + parseInt(current.score, 10);
                        }, 0);
                        setScore(totalScore);
                        setIsSubmitted(true);
                    }

                    setNextCourseId(response.data.data.nextCourse?.id || null);
                } else {
                    setQuestions([]);
                    setError('No questions available for this quiz.');
                }
                setLoading(false); 
            })
            .catch(() => {
                setError('Error fetching questions');
                displayError('Error fetching questions');
                setLoading(false); 
            });
    };

    const handleRadioChange = (questionId, choiceId) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: choiceId,
        }));
    };

    const goToNextPage = () => {
        if (nextCourseId) {
            navigate(`/course/${nextCourseId}`);
        } else {
            navigate('/');
        }
    };

    const handleRetakeQuiz = () => {
        setIsSubmitted(false);
        setScore(0);
        setAnswers({});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // setQuizSubmitted(true);

        const payload = {
            answers: Object.keys(answers).map(questionId => ({
                question_id: questionId,
                answer_id: answers[questionId],
            })),
        };

        setLoading(true); 
        

        api.post('/courses/submit/quiz', payload)
            .then(response => {
                if (response.data.success) {
                    setIsSubmitted(true);
                    displaySuccess(response.data.message);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    displayError(response.data.errors);
                }
                setTimeout(() => {
                setLoading(false); 
               }, 3000);
            })
            .catch(error => {
                displayError('Error submitting quiz:', error);
                setLoading(false); 
            });
            // setQuizSubmitted(false)
    };

    const progressPercentage = (score / points) * 100;
    const progressBarClass = progressPercentage < 50 ? 'progress-bar red' : 'progress-bar green';
    const resultBoxClass = progressPercentage < 50 ? 'result_box red' : 'result_box green';
    const scoreBox = progressPercentage < 50 ? 'score red' : 'score green';
    const resultMsg = progressPercentage < 50 ? 'result_message red' : 'result_message green';
    const resultMessage = progressPercentage < 50
        ? "You didn't pass this time, study up with our recommended resources and try again"
        : 'Congratulations! Excellent Job';
    const btnControls = progressPercentage < 50 ? 'btn_control red' : 'btn_control green';
    const btnText = progressPercentage < 50 ? 'Retake Quiz' : 'Next Course';

    return (
        <div className='quiz_body'>
            <div className="quiz_header">
                <span><i className='bx bx-left-arrow-alt'></i> BACK</span>
                <div className='quiz_title'>
                    <h1>{course.title}</h1>
                    <span>{truncateText(course?.description, 350)}</span>
                </div>
                <div className='quiz_mins'>
                    {course.category} . <span>+{course.points} points</span> > 2 hours 30 mins
                </div>
            </div>

            {isSubmitted && !loading ? (
                <div className={resultBoxClass}>
                    <div className="result_container">
                        <h1>{course.title}</h1>
                        <p className={resultMsg}>{resultMessage}</p>
                        <div className='progress-bar-container'>
                            <div className={progressBarClass} style={{ width: `${progressPercentage}%` }}>
                                {progressPercentage.toFixed(0)}%
                            </div>
                        </div>
                        <div className='score'>
                            <h3>Score: <span className={scoreBox}>{score}/{points}</span></h3>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                            {nextCourseId || progressPercentage < 50 ? (
                                <button className={btnControls} onClick={progressPercentage < 50 ? handleRetakeQuiz : goToNextPage}>
                                    {btnText}
                                </button>
                            ) : (
                                <button className={btnControls} onClick={() => navigate('/')}>
                                    Module Completed, Go Home
                                </button>
                            )}
                        </div>
                    </div>
                </div> 
            ): (
                <div>l...</div>
            )}
            {/* {!isSubmitted && (
                <div style={{ display:'flex',justifyContent:'center' , background : 'green', color: '#fff', padding : '2rem'}}>
                 <h3 style={{ fontSize:'1.4rem' }}>Please wait while we submit your quiz</h3>
                </div>
            )} */}
            { questions.length > 0 ? (
                <div className='quiz'>
                    <form className='quiz' onSubmit={handleSubmit}>
                        {questions.map((question, index) => (
                            <div className="questions" key={question.id}>
                                <h2>Question {index + 1}</h2>
                                <h4>{question.question_text}</h4>
                                <span>Select one answer</span>
                                <div className='answers'>
                                    <ul>
                                        {question.choices.map((choice) => {
                                            const isCorrect = question.user_answers?.choice_id === choice.id && question.user_answers?.is_correct;
                                            const isIncorrect = question.user_answers?.choice_id === choice.id && !question.user_answers?.is_correct;

                                            return (
                                                <li key={choice.id} className={isSubmitted ? (isCorrect ? 'correct' : (isIncorrect ? 'incorrect' : '')) : ''}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`question-${question.id}`}
                                                            value={choice.id}
                                                            checked={answers[question.id] === choice.id}
                                                            onChange={() => handleRadioChange(question.id, choice.id)}
                                                            disabled={isSubmitted}
                                                        />
                                                        {choice.choice_text}
                                                    </label>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        {!isSubmitted && <button type="submit">Submit Quiz</button>}
                    </form>
                </div>
            ) : (
                <div className="empty_questions">
                    <h3>No questions set for this course</h3>
                    <button onClick={goToNextPage}>Next Course</button>
                </div>
            )}
        </div>
    );
}
