import './App.css';
import { Routes, Route , BrowserRouter as Router} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Layouts from "./components/shared/Layout";
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import 'boxicons/css/boxicons.min.css';
import PasswordReset from './components/PasswordReset';
import NewPassword from './components/NewPassword';
import PasswordResetSuccess from './components/PasswordResetSuccess';
import { AuthProvider } from './AuthContext';
import MessageHandler from './components/MessageHandler';
import { MessageProvider } from './components/MessageContext';
import Dashboard from './components/Protected/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Courses from './components/Protected/Courses/Courses';
import Course from './components/Protected/Course/Course';
import Quiz from './components/Protected/Quiz/Quiz';
import Loader from './components/Protected/Loader/Loader.jsx'
import Leaderboard from './components/Protected/Leaderboard/Leaderboard.jsx';
import Settings from './components/Protected/Settings/Settings.jsx';
import ProfileUpdate from './components/Protected/Profile/ProfileUpdate.jsx';



function App() {

    const [loading, setLoading] = useState(true);
 
   
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000); 
    }, []);


  return (
    <div className="App">
    {loading ? (
        <Loader />
      ) : (
      <AuthProvider>
      <MessageProvider>
        <Router>
        <MessageHandler /> 
            <Routes>
              <Route path="/" element={<Layouts/>}>

               <Route path="/" element={<PublicRoute />}>
                    <Route index  element={<Login/>} />
                </Route>
                
                <Route path="/forgot-password" element={<PublicRoute />}>
                    <Route path='/forgot-password'  element={<ForgotPassword/>} />
                </Route>

                <Route path="/password-reset" element={<PublicRoute />}>
                     <Route path='/password-reset'  element={<PasswordReset/>} />
                </Route>
               
                <Route path="/new-password" element={<PublicRoute />}>
                    <Route path='/new-password'  element={<NewPassword/>} />
                </Route>

                <Route path="/password-reset-successful" element={<PublicRoute />}>
                    <Route path='/password-reset-successful'  element={<PasswordResetSuccess/>} />
                </Route>

                <Route path="dashboard" element={<PrivateRoute />}>
                   <Route path="" element={<Dashboard />} />
               </Route>

               <Route path="courses" element={<PrivateRoute />}>
                   <Route path="" element={<Courses />} />
               </Route>

               <Route path="course/:id" element={<PrivateRoute />}>
                   <Route  path="" element={<Course />} />
               </Route>

               <Route path="quiz/:id" element={<PrivateRoute />}>
                   <Route  path="" element={<Quiz />} />
               </Route>

               <Route path="leaderboard" element={<PrivateRoute />}>
                   <Route  path="" element={<Leaderboard />} />
               </Route>

               <Route path="profile-settings" element={<PrivateRoute />}>
                   <Route  path="" element={<Settings />} />
               </Route>

               <Route path="profile-update/:id" element={<PrivateRoute />}>
                   <Route  path="" element={<ProfileUpdate />} />
               </Route>


              </Route>
          </Routes>
          </Router>
          </MessageProvider>
      </AuthProvider>
    )}
    </div>
  );
}

export default App;
