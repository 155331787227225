import React from 'react';

const SuccessCard = ({ message }) => {
  return (
    <div className='success-card'>
      <strong>Success:</strong> {message}
    </div>
  );
};

export default SuccessCard;
