import React ,{useState,useContext}  from 'react'
import logo from '../assets/logo.png'
import ForgotPasswordImg from '../assets/forgot_password_img.png';
import thinkEmoji  from '../assets/thinking_emoji.png';
import lockReset from '../assets/lock_reset.png';
import { MessageContext } from './MessageContext';
import { Link , useNavigate } from 'react-router-dom';
import api from  '../api';
import  style  from './ForgotPassword.module.css';
import ForgotPasswordImgMobile from '../assets/password_reset_mobile.png';


export default function ForgotPassword() {

   const [email, setEmail] = useState('');

   const [buttonText, setButtonText] = useState('Reset Password');

   const navigate = useNavigate(); 


   const { displayError ,displaySuccess} = useContext(MessageContext);

   const styles = {
      container: {
      //   height: '100vh',
        backgroundImage: `url(${ForgotPasswordImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '500px',  // Set your desired width
        height: '100%',
      }
    };

       
    const handleForgotPassword = async (e) => {
      e.preventDefault();

   
  
      try {
         setButtonText('Please wait ...')
      await api.post('/forgot/password', { email })
        .then(response => {
           if(response.data.success){
            navigate(`/password-reset?email=${encodeURIComponent(email)}`);
            displaySuccess('Reset instructions sent to your email.')
           }else{
            displayError(response.data.errors);
           }
        });

       
     
      } catch (error) {

         displayError(error.response?.data?.message || 'Failed to send reset instructions. Please try again.')
       
      }
      setButtonText('Reset Password')
    };
  return (
    <div>
        <header className='login-header'>
         <div className="logo">
             <div>
                <img src={logo} alt="logo"/>
             </div>
             <div>
                <span>Learning Resource</span>
             </div>
         </div>
      </header>
      <section className='login_box'>
         {/* <div className="login_img" id="login_img" style={styles.container}></div> */}
         <div className={style.imageContainer}>
          <img
            className={`${style.loginImg} ${style.desktopImage}`}
            src={ForgotPasswordImg}
            alt="Login Illustration"
          />
          <img
            className={`${style.loginImg} ${style.mobileImage}`}
            src={ForgotPasswordImgMobile}
            alt="Login Illustration Mobile"
          />
        </div>
         <div className="login_section" id="login_section">
            <div className="login_container" id="login_container">
            <div className='header_img'>
                  <img src={lockReset} alt='in-box'/>
               </div>
               <div className="login_header" id="login_header">
                  <h3>Forgotten Password ?  <img src={thinkEmoji} alt='think-emoji' /></h3>
                  <span>No worries we'll send you reset instructions </span>
               </div>
               <form onSubmit={handleForgotPassword}>
                     <div className='form_box'>
                        <label>Email</label>
                        <input type='email' 
                              className='form_input'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder='Email' required/>
                     </div>
                  
                     <div className='btn_box'>
                        <button type='submit'>{buttonText}</button>
                     </div>
               </form>
               <div className='back_to_login_box'>
               <i className='bx bx-left-arrow-alt'></i> <Link to='/'>Back to Log in </Link>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
