import React, {useEffect, useState , useContext , useRef  }  from 'react';
import './ProfileUpdate.css';
import { MessageContext } from '../../MessageContext';
import leaderBoardImg from '../../../assets/learner_img.png';
import Avatar from '../Avatar/Avatar';
import api from '../../../api';
import { useAuth } from '../../../AuthContext';



export default function ProfileUpdate() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [gender , setGender] = useState(['Male','Female']);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [ImageSet ,setImageSet] = useState(false)
  const fileInputRef = useRef(null);


  const { initials} = useAuth();


  const { displayError ,displaySuccess} = useContext(MessageContext);

  // const splitName = (name) => {
  //   const nameParts = name.trim().split(' '); 
  //   setFirstName(nameParts[0]); 
  //   setLastName(nameParts.length > 1 ? nameParts.slice(1).join(' ') : ''); 
  // };
  const splitName = (name) => {

    if (typeof name !== 'string' || !name) {
      setFirstName('');
      setLastName('');
      return;
    }
    const nameParts = name.trim().split(' '); 
    
    setFirstName(nameParts[0]?.charAt(0).toUpperCase()); 
    setLastName(nameParts.length > 1 ? nameParts?.slice(1).join(' ').charAt(0).toUpperCase() : ''); 
  
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      
    }
  };
 
    const handleUpload = async () => {

      if (!selectedImage) {
        displayError('Please select an image first.');
        return;
      }

      const formData = new FormData();

      formData.append('image', selectedImage);

      await api.post(`/courses/learner/update/profile/image`,formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }).then(response => {
                  
                    if(response.data.success){
                      setSelectedImage(null)
                      // setImageSet(true)
                      displaySuccess(response.data.message)
                    }else{
                      displayError(response.data.errors)
                      setSelectedImage(null)
                    }
                })
              .catch(error => {

              displayError(error.response?.data?.errors || 'Error uploading image');
              console.error('Error uploading image:', error);

              });
  
    };


  useEffect(() => {

    api.get(`/courses/learner/details`)  
      .then(response => {
     
       if(response.data.success){
        
           splitName(response.data.data.name)
           setUserDetails(response.data.data)
           console.log(typeof userDetails.img == 'undefined' )
           console.log( userDetails.img )
           setImageSet(response.data.data.img !== undefined && response.data.data.img !== '');
         
          
          
          displaySuccess(response.data.message)
       }else{
          displayError(response.data.errors)
          setUserDetails({});
       }
        
      })
      .catch(error => {
       // displayError(error.response.data.errors);
       //  console.error('Error fetching videos:', error);
       displayError(error.response?.data?.errors || 'Error fetching profile');
       console.error('Error fetching videos:', error);
      });
    //   id, displayError, displaySuccess
  }, []);


  const fetchCountries = async () => {
    await api.get(`/courses/learner/countries`)  
    .then(response => {
         console.log(response)
        if(response.data.success){
          setCountries(response.data.data);
      
        }else{
            displayError(response.data.errors)
            setCountries([]);
        }
      
    })
    .catch(error => {
     // displayError(error.response.data.errors);
     //  console.error('Error fetching videos:', error);
     displayError(error.response?.data?.errors || 'Error fetching profile');
     console.error('Error fetching videos:', error);
    });
  }

  const fetchStates = async () => { 
    await api.get(`/courses/learner/state/${selectedCountry}`)  
    .then(response => {
        if(response.data.success){
          setStates(response.data.data);
      
        }else{
            displayError(response.data.errors)
            setStates([]);
        }
      
    })
    .catch(error => {
     // displayError(error.response.data.errors);
     //  console.error('Error fetching videos:', error);
     displayError(error.response?.data?.errors || 'Error fetching profile');
     console.error('Error fetching videos:', error);
    });
    
  }
 

    useEffect(() => {

      fetchCountries();
    
    }, []);
  

    useEffect(() => {
      fetchStates();
    }, [selectedCountry]);
  
    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
      setSelectedState('');
    };
  
    const handleStateChange = (event) => {
      setSelectedState(event.target.value);
    };
  
  return (
    <div className='profileImgBox'>
         <div className="profile_imagebox">
              <div className="profile_img">
            {/* {ImageSet ? 
                
                 <Avatar firstName={initials.firstInitial} lastName={initials.lastInitial} /> :
              <img src={userDetails.img} alt='star-img'/>
              }  */}
              {ImageSet && userDetails.img ? (
               <img src={userDetails.img} alt='Profile' />
              ) : (
                <Avatar firstName={initials.firstInitial} lastName={initials.lastInitial} />
              )}
              {/* {ImageSet && userDetails.img ? (
               <img src={userDetails.img} alt='Profile' />
              ) : (
                <Avatar firstName={firstName} lastName={lastName} />
              )} */}
              </div>
              <div className="profile_upload_buttons">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                  />
                  <button  onClick={() => fileInputRef.current.click()}>Add Profile</button>
                  <button onClick={handleUpload}>Upload Image</button>
                      
              </div>
         </div>

         <div>
            <div  className="profile_form">
                <div className="profile_form_field">
                  <label>First Name *</label>
                  <input type='text' value={userDetails.name}/>
                </div>
                <div className="profile_form_field">
                  <label>Email *</label>
                  <input type='text' value={userDetails.email}/>
                </div>
              </div>
              <div className="profile_form">
                <div className="profile_form_field">
                  <label>Phone Number *</label>
                  <input type='text' value={userDetails?.phone_number}/>
                </div>
                <div className="profile_form_field">
                  <label>Gender *</label>
                  <select>
                  <option>Select Gender</option>
                  {gender.map((g, index) => (
                      <option key={index} value={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="profile_form">
               
                <div className="profile_form_field">
                  <label>Country *</label>
                  <select value={selectedCountry} onChange={handleCountryChange}>
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="profile_form_field">
                  <label>State *</label>
                  
                    <select  onChange={handleStateChange}>
                      <option value="">Select State</option>
                      {states.map((state, index) => (
                        <option key={index} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  
                </div>
              </div>
              <div className="profile_form">
               
                <div className="profile_form_field">
                  <label>Address *</label>
                  <input type='text'/>
                </div>
              </div>
              <div className='updateButton'>
                <button>Update Information</button>
              </div>
              
         </div>
    </div>
  )
}
