import React ,{useEffect, useState ,useContext} from 'react';
import dashStatMask from '../../../assets/dash_mask.png';
import dashStatImg from '../../../assets/dash_img.png';
import VideoPlayer from  '../VideoPlayer/VideoPlayer';
import { Link } from 'react-router-dom';
import './Dashboard.css'
import api from '../../../api';
import { MessageContext } from '../../MessageContext';
import starImg from '../../../assets/star.png';
import leaderBoardImg from '../../../assets/learner_img.png';

export default function Dashboard() {

   const [videos, setVideos] = useState([]);
   const [statistics , setStatistics] = useState({});

   const { displayError ,displaySuccess} = useContext(MessageContext);
   const [durationType, setDurationType] = useState('all_time'); 
   const [leaderboards , setLeaderboards] = useState([]);
   // const {  } = useContext(MessageContext);

   useEffect(() => {
      api.get('/courses/recently-watched')  
        .then(response => {
         // console.log(response)
         if(response.data.success){
            setVideos(response.data.data);
            displaySuccess(response.data.message)
         }else{
            displaySuccess(response.data.errors)
            setVideos([]);
         }
          
        })
        .catch(error => {
         displayError(error.response.data.errors);
          console.error('Error fetching videos:', error);
        });
    }, []);

    useEffect(() => {

      api.get('/courses/dashboard/statistics')

      .then(response => {

         if(response.data.success){
            setStatistics(response.data.data)
            // displaySuccess(response.data.message)
         }else{
            displaySuccess(response.data.errors)
            
         }
          
        })
        .catch(error => {
         displayError(error.response.data.errors);
          console.error('Error fetching videos:', error);
        });

    } ,[])

    const formatDate = () => {
      const date = new Date(); 
    
     
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    
      
      const month = date.toLocaleDateString('en-US', { month: 'long' });
    
  
      const day = date.getDate();
    
    
      const year = date.getFullYear();
    
     
      const formattedDate = `Today, ${dayOfWeek}, ${month} ${day} ${year}`;
    
      return formattedDate;
    };


    const fetchLeaderboardData = (type) => {
      api.get(`/courses/leaderboard/scores/2?type=${type}`)
        .then(response => {
          console.log(response);
          if (response.data.success) {
            setLeaderboards(response.data.data.leaderboards.data)
            displaySuccess(response.data.message);
          } else {
            displayError(response.data.errors);
          }
        })
        .catch(error => {
         //  displayError(error.response.data.errors);
        });
    };


    useEffect(() => {
      fetchLeaderboardData(durationType);
  }, [durationType]);
    

   const styles = {
      container: {
        backgroundImage: `url(${dashStatMask})`,
        backgroundRepeat: 'no-repeat', 
        width: '15%',  

      },
      statContainer: {
         backgroundImage: `url(${dashStatImg})`,
         backgroundRepeat: 'no-repeat', 
         width: '15%',  
 
       }
    };
  return (
    <div className='dashboard'>
       <div className="welcome_box">
            <h3>Welcome,{statistics.user?.name}</h3>
            <div className='welcome_header'>
               <p>Let's start learning</p>
               <p>{formatDate()}</p>
            </div>
       </div>

       <div className="dashboard_stats">
     
         <div className="dashboard_mask" style={styles.container}></div>
         <div className="dashboard_stat_img">
            <img src={dashStatImg} alt='dashboard-img'/>
         </div>
         <div className="stats" id="stats">
              <div className="stat" id="stat">
                 <h3>{statistics?.completedCourses}</h3>
                 <span>Completed Courses</span>
              </div>
              <div className="stat" id="stat">
                 <h3>{statistics?.userPoints}</h3>
                 <span>Points Acquired</span>
              </div>
              <div className="stat" id="stat">
                 <h3>4</h3>
                 <span>Badges</span>
              </div>
         </div>

       </div>

     {videos.length > 0 ?
       <h3 className='dashboard_header_text'>Pick up where you left off</h3> :''
     }
     
     {videos.length > 0 ?
       <div className="view_courses" id="view_courses">
          <Link to='/courses'>View Courses</Link>
       </div>
       : ''
     }

        <div className="video_component">
            {videos.map((video, index) => (
               <VideoPlayer
                  key={index}
                  videoUrl={video.course.video_url}
                  topic={video.course.title}
                  duration={video.course.duration}
                  points={video.course.points}
                  category={video.course.category}
                  id={video.course.id}
               />
      ))}
            
        </div>

        <div className="leaderboard_dash" id="leaderboard_dash">
         <h3>Leaderboard</h3>
          <div className="leaderboard_header">
             <div className="leaderboard_buttons">
               <button
                  onClick={() => setDurationType('all_time')}
                  className={durationType === 'all_time' ? 'active' : ''}
               >
               All
               </button>

               <button 
                 onClick={() => setDurationType('this_week')}
                 className={durationType === 'this_week' ? 'active' : ''}
               >
               This week
               </button>
             </div>
             <div className='view_leaderboard_button'>
                <Link to='/leaderboard' >View LeaderBoard</Link>
             </div>
          </div>
          <div className="leaderboard_box">
          {leaderboards.map((leaderboard, index) => (
               <div className="leaderboard_container">
                   <div className="leadboard_details">
                       <div className='leaderboard_profile'>
                           <img src={starImg} alt='star-img'/>
                           <img src={leaderBoardImg} alt='leaderboard-img'/>
                           <div>
                              <p>{leaderboard.name}</p>
                           </div>
                       </div>
                 
                   </div>
                   <div className="leaderboard_points">
                        <p>Points Acquired</p>
                        <h3>{leaderboard.total_score}</h3>
                   </div>
               </div>
            ))}
               {/* <div className="leaderboard_container">
                   <div className="leadboard_details">
                       <div className='leaderboard_profile'>
                           <img src={starImg} alt='star-img'/>
                           <img src={leaderBoardImg} alt='leaderboard-img'/>
                           <div>
                              <p>Ayansola Olawuyi</p>
                           </div>
                       </div>
                 
                   </div>
                   <div className="leaderboard_points">
                        <p>Points Acquired</p>
                        <h3>2000</h3>
                   </div>
               </div>
               <div className="leaderboard_container">
                   <div className="leadboard_details">
                       <div className='leaderboard_profile'>
                           <img src={starImg} alt='star-img'/>
                           <img src={leaderBoardImg} alt='leaderboard-img'/>
                           <div>
                              <p>Ayansola Olawuyi</p>
                           </div>
                       </div>
                 
                   </div>
                   <div className="leaderboard_points">
                        <p>Points Acquired</p>
                        <h3>2000</h3>
                   </div>
               </div> */}
          </div>
        </div>


    </div>
  )
}
