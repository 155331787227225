import React, { useState, useEffect } from 'react';
import './Loader.css';


const Loader = () => (
  <div className="loader">
    <div className="spinner"></div> 
    Loading...
  </div>
);

export default Loader;