import React, { useRef, useState ,useEffect} from 'react';
// import { Link } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'; 
import ReactPlayer from 'react-player';
import Modal from '../Modal/Modal';
import './Video.css';

const VideoPlayer = ({ videoUrl, topic, duration, points ,category,id}) => {

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(5);

  // const handlePlayPause = () => {
  //   if (videoRef.current) {
  //     if (isPlaying) {
  //       videoRef.current.pause();
  //       setIsPlaying(false);
  //     } else {
  //       videoRef.current.play()
  //         .then(() => {
  //           setIsPlaying(true);
  //         })
  //         .catch(error => {
  //           console.error('Error attempting to play:', error);
  //         });
  //     }
  //   }
  // };

  useEffect(() => {
    let timer;
    if(isPlaying === true){
      setIsModalOpen(true);
   
     if (isModalOpen && countdown > 0) {
       timer = setInterval(() => {
         setCountdown(prevCountdown => prevCountdown - 1);
       }, 1000);
     } else if (countdown === 0) {
      navigate(`/course/${id}`);
     }
    } 
    return () => clearInterval(timer);
}, [isModalOpen, countdown,isPlaying]);

  const handlePlayPause = () => {
    navigate(`/course/${id}`);
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.seekTo(videoRef.current.getCurrentTime()); // Pause will be handled by ReactPlayer
        setIsPlaying(false);
      } else {
        videoRef.current.seekTo(0); // Start the video from the beginning
        setIsPlaying(true);
      }
    }
  };

  return (
    <div className="video-player">
     
      {/* <video ref={videoRef} controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

{/* <div className="video-container"> */}
      <ReactPlayer
        ref={videoRef}
        url={videoUrl}
        controls
        width="100%"
        height="100%"
        playing={isPlaying}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // Example configuration to disable download
            }
          }
        }}
      />
     {/* </div> */}
      <div className="controls">
        <div className="play_n_pause">
          <span className="control">
            {isPlaying ? (
              <i className='bx bx-pause' onClick={handlePlayPause}></i>
            ) : (
              <span>
                <i className='bx bx-play' onClick={handlePlayPause}></i>
                <span>Start Learning</span>
              </span>
            )}
           
          </span>
        </div>
        <div className="topic_n_points">
          <span className="topic">
            {category}
          </span>
          <span className="duration">
            {duration}
          </span>
          <span className="points">
            +{points}
          </span>
        </div>
      </div>

      <div className="description">
         <Link to={`/course/${id}`}>
            <h3>{topic}</h3>
         </Link> 
      </div>

      <div className='modal_box'>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2>Hello Superstar!</h2>
          <p>Hold on while we redirect you to video single page.</p>
          <h3>Redirecting to video single page  in {countdown} seconds...</h3> 
          {/* <div className='modal_buttons'>
            <Link to={`/quiz/${id}`}>Take Quiz</Link>
          </div> */}
        </Modal>
      </div>
  
    </div>
  );
};

export default VideoPlayer;

