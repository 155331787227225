import React, { createContext, useState, useEffect } from 'react';
import api from './api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [initials, setInitials] = useState({ firstInitial: '', lastInitial: '' });


  // const splitName = (name) => {
  //   if (typeof name !== 'string' || !name) {
  //     setInitials({ firstInitial: '', lastInitial: '' });
  //     return;
  //   }
  //   const nameParts = name.trim().split(' ');
  //   const firstInitial = nameParts[0]?.charAt(0).toUpperCase();
  //   const lastInitial = nameParts.length > 1 ? nameParts.slice(1).join(' ').charAt(0).toUpperCase() : '';
  //   setInitials({ firstInitial, lastInitial });
  // };

    const splitName = (name) => {
      if (typeof name !== 'string' || !name.trim()) {
        setInitials({ firstInitial: '', lastInitial: '' });
        return;
      }
  
      const nameParts = name.trim().split(' ');
  
    
      const firstInitial = nameParts[0]?.charAt(0).toUpperCase() || '';
  
      const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';
  
      setInitials({ firstInitial, lastInitial });
    };



  useEffect(() => {
    const token = localStorage.getItem('token');
   
    if (token) {
      api.get('/validate-token')
        .then(response => {
          if(response.data.valid){
            setUser(response.data);
            splitName(response.data.name);
           
          }else{
            localStorage.removeItem('token');
          }
         
        })
        .catch(() => {
          localStorage.removeItem('token');
        });
    }
  }, []);

  const login = async (email, password) => {
    // await api.get('/sanctum/csrf-cookie'); 
    // await api.get('/sanctum/csrf-cookie');
    
    const response = await api.post('/login', { email, password });
    if(response.data.success){
      const token = response.data.data.access_token;
      const user  = response.data.data.user;
      console.log(user)
      
      localStorage.setItem('token', token);
      localStorage.setItem('userId', user.name);
      setUser(user);
      splitName(user.name); 
     
    }else{
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      setUser(null)
      setInitials({ firstInitial: '', lastInitial: '' });
    }
   
  };

  const logout = () => {
    localStorage.removeItem('token');
    // localStorage.removeItem('userId');
    setUser(null);
    setInitials({ firstInitial: '', lastInitial: '' });
  };


  return (
    <AuthContext.Provider value={{ user, initials,login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => React.useContext(AuthContext);
// export const useAuth = () => {
//   return React.useContext(AuthContext);
// };
