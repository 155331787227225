import React from 'react';

const ErrorCard = ({ messages }) => {
  return (
    <div className='error-card'>
       <strong>Error:</strong>
       <br/>
         <ul>
         {messages.map((message, index) => (
            <li key={index}>{message}</li>
         ))}
         </ul>
    </div>
  );
};

export default ErrorCard;
