
import React , {useEffect, useState ,useContext} from 'react'
import './Courses.css';
import VideoPlayer from  '../VideoPlayer/VideoPlayer';
import { Link } from 'react-router-dom';
import api from '../../../api';
import { MessageContext } from '../../MessageContext';

export default function Courses() {

   const [videos, setVideos] = useState([]);

   const { displayError ,displaySuccess} = useContext(MessageContext);
   const [loading, setLoading] = useState(true);  
   // const {  } = useContext(MessageContext);

   useEffect(() => {
      setLoading(true); 
      api.get('/courses/all')  
        .then(response => {
         if(response.data.success){
            setVideos(response.data.data);
            displaySuccess(response.data.message)
         }else{
            displaySuccess(response.data.errors)
            setVideos([]);
         }
        
            setLoading(false); 
       
        })
        .catch(error => {
         displayError(error.response.data.errors);
          console.error('Error fetching videos:', error);
        });
    }, []);

  return (
    <div className='courses'>
      <h1>Courses</h1>
        {loading ? (
                <div>Loading...</div>  
             ) : (
          <div className="video_component">
            {videos.map((video, index) => (
               <VideoPlayer
                  key={index}
                  videoUrl={video.video_url}
                  topic={video.title}
                  duration={video.duration}
                  points={video.points}
                  id={video.id}
               />
            ))}     
        </div>
      )}
    </div>
  )
}
