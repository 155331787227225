import React from 'react';
import './Avatar.css'; 

const Avatar = ({ firstName, lastName }) => {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return (
    <div className="avatar">
      {firstInitial}{lastInitial}
    </div>
  );
};

export default Avatar;
