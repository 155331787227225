import React, { useState, useRef ,useContext}  from 'react'
import logo from '../assets/logo.png'
import PasswordResetImg from '../assets/password_reset_img.png';
import envelope from '../assets/envelope.png';
import { MessageContext } from './MessageContext';
import { Link , useNavigate , useLocation} from 'react-router-dom';
import PasswordResetImgMobile  from '../assets/pin_reset.png';
import  style  from './PasswordReset.module.css';
import api from  '../api';


const useQuery = () => {
   return new URLSearchParams(useLocation().search);
 }

export default function PasswordReset() {
   const styles = {
      container: {
        backgroundImage: `url(${PasswordResetImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '500px',  
        height: '100%',
      }
    };

   
   
   const [token, setToken] = useState([]);

   const [tokenValues, setTokenValues] = useState(['', '', '', '']); 

   const [buttonText, setButtonText] = useState('Continue');

   const navigate = useNavigate(); 

   const query = useQuery();
   const email = query.get('email');

   const { displayError ,displaySuccess} = useContext(MessageContext);

    const [values, setValues] = useState(['', '', '', '']);
    const inputs = useRef([]);

   
    const maskEmail = (email) =>{
      const [localPart, domainPart] = email.split("@");
      const maskedLocalPart = localPart[0] + "***" + localPart.slice(-1);
      const [domainName, domainExtension] = domainPart.split(".");
      const maskedDomainName = domainName.slice(0, 2) + "***";
      const maskedEmail = `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;
      return maskedEmail;
    }
    
  
    const handleChange = (e, index) => {

        const value = e.target.value;
      //   setTokenValues[index] = value;
      //   console.log(tokenValues)

       
      //   console.log(...value)
      
      //   if (value.length > 1) return;
   
      //   // Copy the current values
      //   const newTokenValues = [...tokenValues];

   
      //   newTokenValues[index] = value;

      //   setTokenValues(newTokenValues);
        
      //   console.log(tokenValues);

      if (/^[0-9]$/.test(value) || value === '') {
        
         const newValues = [...values];
        
         newValues[index] = value;
        
         setValues(newValues);
         console.log(newValues)
        
         if (value !== '' && index < inputs.current.length - 1) {
            inputs.current[index + 1].focus();
         }
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace' && values[index] === '') {
        if (index > 0) {
          inputs.current[index - 1].focus();
        }
      }
   }

   const handleTokenValidation = async (e) => {
      e.preventDefault();

      try {
         setButtonText('Please wait ...')
         // const flattenedToken = tokenValues.join('');
         const flattenedToken = values.join('');
         
      await api.post('/verify/otp', { token : flattenedToken  })
        .then(response => {
         console.log(response)
           if(response.data.success){
            navigate(`/new-password?email=${encodeURIComponent(email)}`);
            displaySuccess(response.data.message)
           }else{
            displayError(response.data.errors);
           }
        });

       
     
      } catch (error) {

         displayError(error.response?.data?.message || 'Failed to send reset instructions. Please try again.')
       
      }
      setButtonText('Reset Password')
    };

    
  return (
    <div>
        <header className='login-header'>
         <div className="logo">
             <div>
                <img src={logo} alt="logo"/>
             </div>
             <div>
                <span>Learning Resource</span>
             </div>
         </div>
      </header>
      <section className='login_box'>
         {/* <div className="login_img" id="login_img" style={styles.container}></div> */}

         <div className={style.imageContainer}>
          <img
            className={`${style.loginImg} ${style.desktopImage}`}
            src={PasswordResetImg}
            alt="Login Illustration"
          />
          <img
            className={`${style.loginImg} ${style.mobileImage}`}
            src={PasswordResetImgMobile}
            alt="Login Illustration Mobile"
          />
        </div>
         <div className="login_section" id="login_section">
            <div className="login_container" id="login_container">
               <div className='header_img'>
                  <img src={envelope} alt='in-box'/>
               </div>
               <div className="login_header" id="login_header">
                  <h3>password reset</h3>
                  <span style={{ marginTop : '1rem' }}>we sent a code to <span style={{ fontWeight:'400', color: '#000'}}> {maskEmail(email) }</span></span>
               </div>
               <form onSubmit={handleTokenValidation }>

               <div style={{ display: 'flex', justifyContent: 'center',marginTop:'2rem' }}>
                        {values.map((value, index) => (
                        <input
                           key={index}
                           type="text"
                           maxLength="1"
                           value={value}
                           onChange={(e) => handleChange(e, index)}
                           onKeyDown={(e) => handleKeyDown(e, index)}
                           ref={el => inputs.current[index] = el}
                          
                           style={{
                              width: '45px',
                              height: '45px',
                              textAlign: 'center',
                              fontSize: '24px',
                              border: '1px solid #000',
                              borderRadius: '4px',
                              marginRight : '1rem',
                           }}
                        />
                        ))}
                  </div>
                     
                  
                     <div className='btn_box'>
                        <button>{buttonText}</button>
                     </div>
               </form>
               <div className='back_to_login_box'>
               <p>Didn't receieve the mail ? <a>click to resend</a></p>
               <i className='bx bx-left-arrow-alt'></i> <Link to='/'>Back to Log in </Link>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
