import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  // admin.promisetracker.ng
  // promise_tracker_backend.test
  baseURL: 'https://admin.promisetracker.ng/api/v1/learner',
  headers: {
    'Content-Type': 'application/json',
  },
});





api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

 
  return config;
});

export default api;


// import axios from 'axios';

// // Create Axios instance
// const api = axios.create({
//   baseURL: 'https://promise_tracker_backend.test/api/v1/learner',
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept':'application/json'
//   },
//   withCredentials: true, // Important for sending cookies
//   withXSRFToken: true,
// });

// // Function to fetch CSRF token
// // const fetchCsrfToken = async () => {
// //   try {
// //     await api.get('https://promise_tracker_backend.test/sanctum/csrf-cookie');
 
// //   } catch (error) {
// //     console.error('Error fetching CSRF token:', error);
// //   }
// // };


// api.interceptors.request.use(
//   async (config) => {
   
//     //await api.get('/sanctum/csrf-cookie'); 
//     let csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

//     console.log(csrfToken)
//     console.log('csrfToken')

//     if (!csrfToken) {
//       // await fetchCsrfToken();
//       csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
//     }

   
//     if (csrfToken) {
//       config.headers['X-CSRF-TOKEN'] = csrfToken;
//     }

    


//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default api;





