import React, { useState ,useContext } from 'react'
import logo from '../assets/logo.png'
import SetNewPasswordImg from '../assets/set_new_pass_img.png';
import SetNewPasswordImgMobile from '../assets/new_pass.png';
import lock from '../assets/lock.png';
import { Link , useLocation ,useNavigate } from 'react-router-dom';
import { MessageContext } from './MessageContext';
import api from  '../api';
import  style  from './NewPassword.module.css';

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
 }


export default function NewPassword() {

   const query = useQuery();
   const email = query.get('email');
   const [password , setPassword] = useState('')
   const [confirmPassword , setConfirmPassword] = useState('')
   const [buttonText, setButtonText] = useState('Reset Password');
   const navigate = useNavigate(); 

   const { displayError ,displaySuccess} = useContext(MessageContext);

   const styles = {
      container: {
      //   height: '100vh',
        backgroundImage: `url(${SetNewPasswordImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '500px',  // Set your desired width
        height: '100%',
      }
    };

    const handlePasswordReset = async (e) => {

      e.preventDefault();

      try {
         if (password.length < 8) {
            displayError("Password must be at least 8 characters long.");
          } else if (password !== confirmPassword) {
            displayError("Passwords do not match.");
          } else {

            setButtonText('Please wait ...')
            
            await api.post('/reset/password', { 
               email : email ,
               password :password ,
               password_confirmation : confirmPassword})
           .then(response => {
            console.log(response)
              if(response.data.success){
               navigate('/password-reset-successful');
               displaySuccess(response.data.message)
              }else{
               displayError(response.data.errors);
              }
           });

          }
     
      } catch (error) {
         displayError(error.response?.data?.message || 'Failed to send reset instructions. Please try again.')
      }

      setButtonText('Reset Password')
    };
  return (
    <div>
        <header className='login-header'>
         <div className="logo">
             <div>
                <img src={logo} alt="logo"/>
             </div>
             <div>
                <span>Learning Resource</span>
             </div>
         </div>
      </header>
      <section className='login_box'>
         {/* <div className="login_img" id="login_img" style={styles.container}></div> */}
         <div className={style.imageContainer}>
          <img
            className={`${style.loginImg} ${style.desktopImage}`}
            src={SetNewPasswordImg}
            alt="Login Illustration"
          />
          <img
            className={`${style.loginImg} ${style.mobileImage}`}
            src={SetNewPasswordImgMobile}
            alt="Login Illustration Mobile"
          />
        </div>
         <div className="login_section" id="login_section">
            <div className="login_container" id="login_container">
            <div className='header_img'>
                  <img src={lock} alt='in-box'/>
               </div>
               <div className="login_header" id="login_header">
                  <h3>Set New Password</h3>
                  <span>Must be atleast 8 characters</span>
               </div>
                 <form onSubmit={handlePasswordReset}>
                     <div className='form_box'>
                        <label>New Password</label>
                        <input type='password' 
                        className='form_input' 
                        placeholder='New Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}

                        />
                     </div>

                     <div className='form_box'>
                        <label>Confirm Password</label>
                        <input type='password' 
                        className='form_input' 
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}


                        />
                     </div>
                  
                     <div className='btn_box'>
                        <button>{buttonText}</button>
                     </div>
                 </form>
               <div className='back_to_login_box'>
               <i className='bx bx-left-arrow-alt'></i> <Link to='/'>Back to Log in </Link>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
