import React, { useContext } from 'react';
import { MessageContext } from './MessageContext';
import ErrorCard from './Errors/ErrorCard';
import SuccessCard from './Success/SuccessCard';

const MessageHandler = () => {
  const { messages, type } = useContext(MessageContext);

  return (
    <div>
      {type === 'error' && <ErrorCard messages={messages} />}
      {type === 'success' && <SuccessCard message={messages} />}
      {/* {type === 'error' && messages && messages.map((msg, index) => (
        <ErrorCard key={index} message={msg} />
      ))}
      {type === 'success' && messages && messages.map((msg, index) => (
        <SuccessCard key={index} message={msg} />
      ))} */}
    </div>
  );
};

export default MessageHandler;
