import React from 'react'
import logo from '../assets/logo.png'
import PasswordSuccess from '../assets/password_success.png';
import PasswordSuccessMobile from '../assets/password_reset_mobile.png';
import verified from '../assets/verified.png';
import { Link } from 'react-router-dom';
import  style  from './PasswordSuccess.module.css';

export default function PasswordResetSuccess() {
   const styles = {
      container: {
        backgroundImage: `url(${PasswordSuccess})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '500px',  
        height: '100%',
      }
    };
  return (
    <div>
         <header className='login-header'>
         <div className="logo">
             <div>
                <img src={logo} alt="logo"/>
             </div>
             <div>
                <span>Learning Resource</span>
             </div>
         </div>
      </header>
      <section className='login_box'>
         {/* <div className="login_img" id="login_img" style={styles.container}></div> */}
         <div className={style.imageContainer}>
          <img
            className={`${style.loginImg} ${style.desktopImage}`}
            src={PasswordSuccess}
            alt="Login Illustration"
          />
          <img
            className={`${style.loginImg} ${style.mobileImage}`}
            src={PasswordSuccessMobile}
            alt="Login Illustration Mobile"
          />
        </div>
         <div className="login_section" id="login_section">
            <div className="login_container" id="login_container">
               <div className='header_img'>
                  <img src={verified} alt='in-box'/>
               </div>
               <div className="login_header" id="login_header">
                  <h3>password reset successful</h3>
                  <span style={{ marginTop : '1rem' }}>Log into your account with the new password</span>
               </div>
               <div className='btn_box'>
                  <button>Reset Password</button>
               </div>
               <div className='back_to_login_box'>
               <i className='bx bx-left-arrow-alt'></i> <Link to='/'>Back to Log in </Link>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
