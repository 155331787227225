import React ,{ useEffect ,useState } from 'react';
import './Settings.css';
import { Link } from 'react-router-dom';
import leaderBoardImg from '../../../assets/learner_img.png';
import api from '../../../api';
import { useAuth } from '../../../AuthContext';
import Avatar from '../Avatar/Avatar';



export default function Settings() {

   const [userDetails , setUserDetails] = useState({});
   const [ImageSet ,setImageSet] = useState(false);
   const { initials} = useAuth();

   useEffect(() => {

      api.get('/courses/learner/details')

      .then(response => {

         if(response.data.success){
            setUserDetails(response.data.data)
            setImageSet(response.data.data.img !== undefined && response.data.data.img !== '');
         
          
            // displaySuccess(response.data.message)
         }else{
       
            setUserDetails({})
         }
          
        })
        .catch(error => {
       
          console.error('Error fetching videos:', error);
        });

    } ,[])
  
  return (
    <div className='profile_container'>
       
       <h2>Profile</h2>

       <div className='profile_box'>
         <div className="profile profile_img">
         {/* <img src={leaderBoardImg} alt='star-img'/> */}
         {ImageSet && userDetails.img ? (
            <img src={userDetails.img} alt='Profile' />
            ) : (
               <Avatar firstName={initials.firstInitial} lastName={initials.lastInitial} />
            )}
          <div className='profileName profileNameBox'>
            <h3>{userDetails.name}</h3>
            <span>{userDetails.state?.name} , {userDetails.country?.name}</span>
          </div>
         </div>
         <div className='editProfile'>
            <Link to={`/profile-update/${userDetails.id}`}><i class='bx bxs-edit-alt' ></i> Edit</Link>
         </div>
       </div>

       <div className='profileinformation_box'>
       <h2>Personal Information</h2>
         <div className="profileInformation">
           <div className='profileItem'>
              <div className='profileName'>
                  <h3>Full Name</h3>
                  <span>{userDetails.name}</span>
               </div>
               <div className='profileName'>
                  <h3>Email</h3>
                  <span>{userDetails.email}</span>
               </div>
           </div>
           <div className="profileItem">
               <div className='profileName'>
                  <h3>Phone Number</h3>
                  <span>{userDetails.phone_number}</span>
               </div>
           </div>
         <div>
         </div>
         </div>
       </div>

       <div className='profileinformation_box'>
       <h2>Address</h2>
         <div className="profileInformation">
           <div className='profileItem'>
              <div className='profileName'>
                  <h3>Country</h3>
                  <span>{userDetails.country?.name}</span>
               </div>
               <div className='profileName'>
                  <h3>State</h3>
                  <span>{userDetails.state?.name}</span>
               </div>
           </div>
           <div className="profileItem">
               <div className='profileName'>
                     <h3>Street</h3>
                     <span>{userDetails.street}</span>
               </div>
           </div>
         <div>
         </div>
         </div>
       </div>

    </div>
  )
}
