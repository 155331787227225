// import React, { useEffect, useRef, useState } from 'react';
// import { Link , useNavigate  } from 'react-router-dom';
// import ReactPlayer from 'react-player';
// import './SingleVideo.css';
// import api from '../../../api';
// import Modal from '../Modal/Modal';

// const SingleVideoPlayer = ({ videoUrl, topic, duration, points, category, id }) => {
  
//   const playerRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [percentageWatched, setPercentageWatched] = useState(0);
//   const [lastReportedTime, setLastReportedTime] = useState(0);
//   const [countdown, setCountdown] = useState(10);


//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const navigate = useNavigate();

//   const storageKey = `video-${id}-time`;

//   const handlePlayPause = () => {
//     if (playerRef.current) {
//       if (isPlaying) {
//         playerRef.current.getInternalPlayer().pause();
//         setIsPlaying(false);
//       } else {
//         playerRef.current.getInternalPlayer().play()
//           .then(() => {
//             setIsPlaying(true);
//           })
//           .catch(error => {
//             console.error('Error attempting to play:', error);
//           });
//       }
//     }
//   };

//   const handleProgress = (state) => {
//     const { playedSeconds, loadedSeconds, played, loaded, duration } = state;

//     const percentage = played * 100;

//     setPercentageWatched(percentage);

//    console.log('hee')

//     // Save current time to local storage
//     localStorage.setItem(storageKey, playedSeconds);

//     // Report to server every 5% change or when video ends
//     if (percentage - lastReportedTime >= 5 || played === 1) {
//       reportWatchStatus(playedSeconds);
//       setLastReportedTime(percentage);
//     }
//   };

//   const handleEnded = () => {
//     console.log('Video has ended');
//     setIsModalOpen(true); 
//     reportWatchStatus(playerRef.current.getCurrentTime()); // Report final time when video ends
//     // setIsPlaying(false); // Set playing state to false when the video ends
//   };

//   const reportWatchStatus = (currentTime) => {
//     api.post('/courses/course-progress', {
//       videoUrl,
//       percentageWatched,
//       currentTime,
//       id,
//     })
//     .then(response => {
//       // Handle response
//     })
//     .catch(error => {
//       console.error('Error reporting video progress:', error);
//     });
//   };

//   useEffect(() => {
//     const savedTime = localStorage.getItem(storageKey);
//     if (savedTime && playerRef.current) {
//       playerRef.current.seekTo(parseFloat(savedTime), 'seconds');
//     }
//   }, [lastReportedTime]);

//   useEffect(() => {
//     let timer;
//     if (isModalOpen && countdown > 0) {
  
//       timer = setInterval(() => {
//         setCountdown((prevCountdown) => prevCountdown - 1);
//       }, 1000);
//     } else if (countdown === 0) {
    
//       navigate(`/quiz/${id}`);
//     }

//     return () => clearInterval(timer);
//   }, [isModalOpen, countdown, navigate]);

//   return (
//     <div className="single-video-player">
//       <ReactPlayer
//         ref={playerRef}
//         url={videoUrl}
//         playing={isPlaying}
//         controls
//         onProgress={handleProgress}
//         onEnded={handleEnded} 
//         width="100%"
//         // height="100%"
//         style={{ borderRadius: '3rem' }} // Add border-radius if needed
//       />
      
    

//       <div className='modal_box'>
//       <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
//         <h2>Congratulations!</h2>
//         <p>You have finished watching the video.</p>
//         <h3>Redirecting to the quiz in {countdown} seconds...</h3> 
//         <div className='modal_buttons'>
//             <Link to={`/quiz/${id}`}>Take Quiz</Link>
//             {/* <button onClick={() => setIsModalOpen(false)}>Close</button> */}
//         </div>
//       </Modal>
//       </div>
//     </div>
//   );
// }

// export default SingleVideoPlayer;


import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import debounce from 'lodash.debounce';
import './SingleVideo.css';
import api from '../../../api';
import Modal from '../Modal/Modal';

const SingleVideoPlayer = ({ videoUrl, topic, duration, points, category, id }) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [percentageWatched, setPercentageWatched] = useState(0);
  const [lastReportedTime, setLastReportedTime] = useState(0);
  const [countdown, setCountdown] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const storageKey = `video-${id}-time`;

  const handlePlayPause = () => {
    if (playerRef.current) {
      const internalPlayer = playerRef.current.getInternalPlayer();
      if (isPlaying) {
        if (internalPlayer.pauseVideo) {
          internalPlayer.pauseVideo();
        } else {
          internalPlayer.pause();
        }
        setIsPlaying(false);
      } else {
        if (internalPlayer.playVideo) {
          internalPlayer.playVideo();
        } else {
          internalPlayer.play();
        }
        setIsPlaying(true);
      }
    }
  };

  const handleProgress = useCallback((state) => {
    const { playedSeconds, played } = state;
    const percentage = played * 100;
    setPercentageWatched(percentage);

    localStorage.setItem(storageKey, playedSeconds);

    if (percentage - lastReportedTime >= 5 || played === 1) {
      reportWatchStatus(playedSeconds);
      setLastReportedTime(percentage);
    }
  }, [lastReportedTime, storageKey]);

  const debouncedProgress = useCallback(debounce(handleProgress, 1000), [handleProgress]);

  const handleEnded = () => {
    console.log('Video has ended');
    setIsModalOpen(true);
    reportWatchStatus(playerRef.current.getCurrentTime());
  };

  const reportWatchStatus = (currentTime) => {
    api.post('/courses/course-progress', {
      videoUrl,
      percentageWatched,
      currentTime,
      id,
    })
    .then(response => {
      // Handle response
      console.log('Progress reported successfully:', response.data);
    })
    .catch(error => {
      console.error('Error reporting video progress:', error);
    });
  };

  useEffect(() => {
    const savedTime = localStorage.getItem(storageKey);
    if (savedTime && playerRef.current) {
      playerRef.current.seekTo(parseFloat(savedTime), 'seconds');
    }
  }, [storageKey]);

  useEffect(() => {
    let timer;
    if (isModalOpen && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      navigate(`/quiz/${id}`);
    }
    return () => clearInterval(timer);
  }, [isModalOpen, countdown, navigate]);

  return (
    <div className="single-video-player">
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        playing={isPlaying}
        controls
        onProgress={debouncedProgress}
        onEnded={handleEnded} 
        width="100%"
        style={{ borderRadius: '3rem' }}
        config={{
          youtube: {
            playerVars: { controls: 1, autoplay: 0 }
          }
        }}
        onError={(e) => console.error('Playback error:', e)}
      />

      <div className='modal_box'>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2>Congratulations!</h2>
          <p>You have finished watching the video.</p>
          <h3>Redirecting to the quiz in {countdown} seconds...</h3> 
          <div className='modal_buttons'>
            <Link to={`/quiz/${id}`}>Take Quiz</Link>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default SingleVideoPlayer;

