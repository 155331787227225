import React ,{useState , useContext} from 'react'
import logo from '../assets/logo.png'
import LoginImg from '../assets/logo_img.png';
import LoginImgMobile from '../assets/logo_login.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { MessageContext } from './MessageContext';
import { useNavigate } from 'react-router-dom';
import  style  from './Login.module.css'

export default function Login() {
   const styles = {
      container: {
      //   height: '100vh',
        backgroundImage: `url(${LoginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '500px',  // Set your desired width
        height: '100%',
      }
    };
  
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const { login } = useAuth();
      const { displayError } = useContext(MessageContext);
      const { displaySuccess } = useContext(MessageContext);
      const navigate = useNavigate(); 
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const errorMessages = [
         'Invalid credentials, please try again.',
         'Ensure your email and password are correct.'
       ];
        try {
         
          const response = await login(email, password);
         
          // Redirect to dashboard or another page
           navigate('/dashboard');
           setTimeout(()=> {
            window.location.reload();
           },1000)
          
        } catch (error) {
         // console.log(error)
         displayError(error.response.data.errors);
         //  console.error( error.response.data.errors);
        }
      };
    
  return (
    <div>
      <header className='login-header'>
         <div className="logo">
             <div>
                <img src={logo} alt="logo"/>
             </div>
             <div>
                <span>Learning Resource</span>
             </div>
         </div>
      </header>
      <section className='login_box'>
         {/* <div className="login_img" id="login_img" style={styles.container}></div> */}
         {/* <div className={style.login_img} id="login_img" ></div> */}
         {/* <img className={style.login_img} src={LoginImg}  id="login_img"  alt="logo"/>
         <img className={style.login_img} src={LoginImgMobile}  id="login_img_mobile"  alt="logo"/> */}
         <div className={style.imageContainer}>
          <img
            className={`${style.loginImg} ${style.desktopImage}`}
            src={LoginImg}
            alt="Login Illustration"
          />
          <img
            className={`${style.loginImg} ${style.mobileImage}`}
            src={LoginImgMobile}
            alt="Login Illustration Mobile"
          />
        </div>
         <div className="login_section" id="login_section">
            <div className="login_container" id="login_container">
               <div className="login_header" id="login_header">
                  <h3>Welcome Back</h3>
                  <span>Enter your email and password to signin</span>
               </div>
               <form onSubmit={handleSubmit}>
                  <div className='form_box'>
                     <label>Email</label>
                     <input type='email' 
                           className='form_input' 
                           placeholder='Email'
                           value={email} 
                           onChange={(e) => setEmail(e.target.value)}/>
                  </div>
                  <div className='form_box'>
                     <label>Password</label>
                     <input type='password' 
                           className='form_input' 
                           placeholder='Password'
                           value={password} onChange={(e) => setPassword(e.target.value)} 

                           />
                  </div>
                  <div className='remember_me'>
                     <input type='checkbox' className='form_input'/>
                     <label>Remeber Me</label>
                  </div>
                  <div className='btn_box'>
                     <button type='submit'>Sign In</button>
                  </div>
               </form>
               <div className='forgot_password_box'>
                  <Link to='forgot-password'>Forgot Password ?</Link>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
